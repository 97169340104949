<script context="module">
	import { io } from 'socket.io-client';
	import { writable } from 'svelte/store';
	import { persistentStore } from './utils';
</script>

<script>
	const socket = io();

	let clientId = writable('');
	let socketId;

	let position = persistentStore('position', 0.5);

	socket.on('connect', (e) => {
		console.log(socket.id);
		socketId = socket.id;

		/* check if clientId exists in localStorage and if not, set socket.id to a new key clientId in localStorage */
		if (!localStorage.getItem('clientId')) {
			localStorage.setItem('clientId', socket.id);
			$clientId = socket.id;
		} else {
			$clientId = localStorage.getItem('clientId');
		}

		socket.emit('client-connected', {
			clientId: $clientId
		});
	});

	$: {
		console.log('position-updated:', $clientId, $position);

		socket.emit('position-updated', {
			clientId: $clientId,
			position: $position
		});
	}
	socket.on('disconnect', (e) => {
		socket.emit('client-disconnected', {
			clientId: $clientId
		});
	});

	const socketEmit = (name) => {
		console.log('name:', name);
		socket.emit(name, { data: name });
	};
</script>

<button on:click={() => socketEmit('spinner')} id="btn-emit">Add Spinner</button>
<button on:click={() => socketEmit('runner')} id="btn-runner">Add Runner</button>
<button on:click={() => socketEmit('clear')} id="btn-clear">Clear</button>

<br />
<input type="range" min="0" max="1" step={0.001} bind:value={$position} />

{$position}

<br />
ClientID: {$clientId}
<br />
SocketID: {socketId}
