import { writable } from 'svelte/store';

export const persistentStore = (name, initialValue) => {
	if (window?.localStorage) {
		const storedValue = window.localStorage.getItem(name);

		if (storedValue) {
			initialValue = storedValue;
		}
	}

	const { subscribe, set } = writable(initialValue);

	return {
		subscribe,
		set: (x) => {
			if (window?.localStorage) {
				window.localStorage.setItem(name, x);
			}
			set(x);
		}
	};
};
